<template>
    <div style="margin-top: 100px">
        <div
            class="container-bg"
            style="margin-top: 5% !important; width: 45%; margin: auto"
        >
            <div class="container-fg">
                <div style="display: flex; flex-direction: row">
                    <img
                        style="width: 130px; margin-bottom: 1%"
                        src="../../assets/logos/getac-logo.svg"
                    />
                    <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%"
                        >PARTS ORDER</div
                    >
                </div>
                <v-divider style="margin-bottom: 1%; border-color: gray" />
                <div style="display: flex; flex-direction: row">
                    <div style="display: block; margin: auto">
                        <p
                            class="text-h7 nav-font text-center"
                            style="
                                display: block;
                                margin: auto;
                                margin-top: 25%;
                            "
                            >PARTS ORDER SUCCESSFULLY</p
                        >
                        <h1
                            style="
                                font-size: 36px;
                                letter-spacing: -0.08rem;
                                margin-bottom: 10%;
                            "
                            class="text-center"
                            >CREATED</h1
                        >
                        <p
                            class="text-h7 nav-font text-center"
                            style="
                                display: block;
                                margin: auto;
                                margin-top: 25%;
                            "
                            >RMA BATCH ID: {{ rmaBatchId }}</p
                        >
                        <br />
                        <v-btn
                            color="primary"
                            outlined
                            class="text-center"
                            style="margin-bottom: 25%; margin-left: 10px"
                            @click="returnHome"
                            >Create Another?</v-btn
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'OrderSuccess',
    props: {
        rmaBatchId: {
            type: String,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            cart: `cart/cart`
        })
    },
    created() {
        this.cart.forEach((item) => {
            this.remove({ ...item })
        })
    },
    methods: {
        returnHome() {
            window.location.reload()
        },
        ...mapActions({
            remove: `cart/remove`
        })
    }
}
</script>
